(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/config/menu-config/views/user-menu-logged-out.js') >= 0) return;  svs.modules.push('/components/config/menu-config/views/user-menu-logged-out.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.config=_svs.config||{};
_svs.config.menu_config=_svs.config.menu_config||{};
_svs.config.menu_config.templates=_svs.config.menu_config.templates||{};
svs.config.menu_config.templates.user_menu_logged_out = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "Logga in";
},"5":function(container,depth0,helpers,partials,data) {
    return "Bli kund";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"navigation-user-logged-out\">\n  <div class=\"navigation-user-divider\"><img src=\""
    + container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/components/ao-logotype-tur/ao-tur.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":112}}}))
    + "\"\n      alt=\"Svenska Spel Tur\" /></div>\n  <div class=\"navigation-user-logged-out-container\">\n    <div class=\"margin-xs-bottom-2 f-center\">\n      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"size":"600","icon":"user"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":50}}})) != null ? stack1 : "")
    + "\n    </div>\n\n    "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-login-home-nav-menu margin-xs-bottom-1","block":true,"focus":true,"size":300},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":116}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-customer-reg-nav-menu","block":true,"transparent":true,"size":300},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":105}}})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"navigation-user-logged-out-dots\"></div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-config-menu_config-user_menu_logged_out'] = svs.config.menu_config.templates.user_menu_logged_out;
})(svs, Handlebars);


 })(window);