(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/config/menu-config/views/user-menu-header.js') >= 0) return;  svs.modules.push('/components/config/menu-config/views/user-menu-header.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.config=_svs.config||{};
_svs.config.menu_config=_svs.config.menu_config||{};
_svs.config.menu_config.templates=_svs.config.menu_config.templates||{};
svs.config.menu_config.templates.user_menu_header = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ssAccountBalance") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":21,"column":56},"end":{"line":21,"column":91}}}))
    + " kr";
},"3":function(container,depth0,helpers,partials,data) {
    return "Visa saldo";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"navigation-user-header\">\n  <div class=\"navigation-user-divider\"><img src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/components/ao-logotype-tur/ao-tur.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":112}}}))
    + "\"\n      alt=\"Svenska Spel Tur\" /></div>\n  <div class=\"navigation-user-header-name js-logged-in data-hj-suppress\">\n    "
    + alias3(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":17}}}) : helper)))
    + "\n  </div>\n\n  <div class=\"navigation-user-header-time margin-xs-left-2\">\n    <div class=\"media\">\n      <div class=\"media-body\">\n        <div class=\"js-session-time navigation-user-header-time-session margin-xs-bottom\"></div>\n        <div class=\"js-last-login-time navigation-user-header-time-last\"></div>\n      </div>\n    </div>\n  </div>\n  <hr class=\"bg-grey-500 margin-xs-v-1 margin-xs-h-2\" />\n  <div class=\"navigation-user-header-account js-account-balance-bar-balance-toggle margin-xs-left-2 pointer\"\n       tabindex=\"0\" role=\"button\">\n    "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"class":"margin-xs-right","color":"fc-grey-700","icon":"balance","size":"300"},"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":87}}}))
    + "\n    <span class=\"js-account-balance-bar-balance-value navigation-user-header-account-amount align-middle\"\n          aria-live=\"assertive\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ssAccountBalance") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":21,"column":32},"end":{"line":21,"column":119}}})) != null ? stack1 : "")
    + "</span>\n  </div>\n  <div class=\"navigation-user-dots\">\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-config-menu_config-user_menu_header'] = svs.config.menu_config.templates.user_menu_header;
})(svs, Handlebars);


 })(window);