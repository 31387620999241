(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/config/menu-config/assets/javascripts/navigation-menu.js') >= 0) return;  svs.modules.push('/components/config/menu-config/assets/javascripts/navigation-menu.js');

'use strict';

let isUserNavMenuDoubleClick = false;
const trackAndNavigate = _ref => {
  let {
    path,
    action
  } = _ref;
  if (isUserNavMenuDoubleClick) {
    return;
  }
  isUserNavMenuDoubleClick = true;
  const track = {
    category: 'right menu',
    action,
    opt_label: action
  };
  svs.components.analytics.trackEvent(track, () => {
    isUserNavMenuDoubleClick = false;
    location.href = path;
  });
};
if (document.querySelector('.js-login-home-nav-menu')) {
  document.querySelector('.js-login-home-nav-menu').addEventListener('click', () => {
    const currentPath = svs.utils.url.currentPathEncodedParams();
    const path = "".concat(svs.core.urlMapping.get('loginHome'), "?extra=no&returnUrl=").concat(currentPath, "&cancelUrl=").concat(currentPath);
    trackAndNavigate({
      path,
      action: 'Logga in'
    });
  });
}
if (document.querySelector('.js-customer-reg-nav-menu')) {
  document.querySelector('.js-customer-reg-nav-menu').addEventListener('click', () => {
    const currentPath = svs.utils.url.currentPathEncodedParams();
    const path = "".concat(svs.core.urlMapping.get('customerRegistrationHome'), "?extra=no&returnUrl=").concat(currentPath, "&cancelUrl=").concat(currentPath);
    trackAndNavigate({
      path,
      action: 'Bli kund'
    });
  });
}

 })(window);